<!-- 确认订单 -->
<template>
    <div class="container">
        <titleText title="确认订单"/>
        <div>
            <div class="info-wrapper">
                <div class="flex">
                    <p class="label">配送方式：</p>
                    <p v-if="fashion===0" class="flex-1 font-bold">
                        <span>送货上门</span>
                    </p>
                    <p v-else-if="fashion===1" class="flex-1 font-bold">
                        <span>快递直发</span>
                    </p>
                </div>
                <template v-if="fashion===0">
                    <div class="flex mt-20">
                        <p class="label">自提点：</p>
                        <div class="flex-1 font-bold">
                            <p>{{userInfo.tXiaoqu}}</p>
                            <p class="mt-5">{{userInfo.tProvince}}{{userInfo.tCity}}{{userInfo.tArea}}{{userInfo.tShopname}}</p>
                        </div>
                    </div>
                    <div class="mt-20 user-wrapper">
                        <el-form :model="receiveInfo" ref="userInfo" label-width="100px">
                            <el-form-item label="收件人姓名：">
                                <el-input v-model="receiveInfo.name" autocomplete="off" placeholder="请填写收件人姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="收件人电话：">
                                <el-input v-model="receiveInfo.mobile" type="tel" autocomplete="off" placeholder="请填写收件人电话"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>            
                </template>
                <template v-if="fashion===1">
                    <div class="flex justify-s-b mt-20">
                        <span class="font-16 font-bold color-333">确认收货地址</span>
                        <span class="add-address" @click="showAddress = true">新增收货地址</span>
                    </div>
                    <ul class="flex align-c flex-wrap mt-20">
                        <template v-if="addressList.length > 0">
                            <li class="address-item" :class="index === active ? 'active' : ''" 
                                v-for="(item, index) in addressList" :key="item.id" @click="changeAddressFn(index)">
                                <template v-if="index === active">
                                    <div class="line line1"></div>
                                    <div class="line line2"></div>
                                    <div class="line line3"></div>
                                    <div class="line line4"></div>
                                </template>
                                <div class="address-item-edit flex align-c" @click.stop="editAddressShowFn(index)">
                                    <i class="el-icon-edit"></i>
                                    <span>编辑</span>
                                </div>
                                <p class="font-16 font-bold">{{item.memName}}</p>
                                <p class="mt-10">{{item.telNumber}}</p>
                                <p class="mt-10">{{item.provinceName}} {{item.cityName}} {{item.countyName}}</p>
                                <p class="mt-10">{{item.detailInfo}}</p>
                            </li>
                        </template>
                        <li v-else class="address-item flex-column align-c justify-c" @click="showAddress = true">
                            <i class="el-icon-plus plus-icon"></i>
                            <span>添加收货地址</span>
                        </li>
                    </ul>
                </template>
            </div>
            <p class="font-bold pt-10 mb-10">选择支付方式</p>
            <div class="payment-type-wrapper flex">
                <div @click="payTypeChangeFn('wx')" class="payment-type wx flex align-c justify-c" :class="payType == 'wx' ? 'active' : ''">
                    <i></i>
                    <span>微信支付</span>
                </div>
                <div v-if="disabledYuPay" class="payment-type ye flex align-c justify-c disabled">
                    <i></i>
                    <span>余额支付（￥{{balance.toFixed(2)}}）</span>
                </div>
                <div v-else @click="payTypeChangeFn('yu')" class="payment-type ye flex align-c justify-c" :class="payType == 'yu' ? 'active' : ''">
                    <i></i>
                    <span>余额支付（￥{{balance.toFixed(2)}}）</span>
                </div>
            </div>
            <div class="good-list-container">
                <div class="th flex align-c">
                    <div class="th-info">商品信息</div>
                    <div class="th-price">单价</div>
                    <div class="th-amount">数量</div>
                    <div class="th-sum">金额</div>
                </div>
                <div class="good-list-wrapper mt-20">
                    <div class="tr flex font-12 " v-for="(item, index) in goodList" :key="item.id">
                        <div class="tr-info flex">
                            <div class="good-image" @click="toGoodDetail(item.productId)">
                                <el-image :src="item.thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 80px; height: 80px;">
                                    <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 80px; height: 80px;">
                                </el-image>
                            </div>
                            <div class="ml-20 good-info">
                                <p class="title" @click="toGoodDetail(item.productId)">{{item.title || item.productName}}</p>
                                <p class="color-666 mt-10">
                                    规格：{{item.specTitle}}
                                    <template v-if="item.unit">
                                        /{{item.unit}}
                                    </template>
                                    <template v-if="item.skuTitle || item.optionname">
                                        /{{item.skuTitle || item.optionname}}
                                    </template>
                                </p>
                                <p class="iden mt-10" v-if="item.productType == 3">秒杀</p>
                            </div>
                        </div>
                        <div class="tr-price">
                            <template v-if="item.productType == 5">￥{{item.marketprice.toFixed(2)}}+{{item.dedcredit}}积分</template>
                            <template v-else>￥{{item.marketprice.toFixed(2)}}</template>
                        </div>
                        <div class="tr-amount">
                            <el-input-number v-model="item.selectNum" :min="1" 
                                    :max="item.maxbuy ? item.maxbuy : 9999" 
                                    @change="(currentValue, oldValue) => ChangeNumFn(currentValue, oldValue, index)" size="mini"></el-input-number>
                        </div>
                        <div class="tr-sum font-14 color-orange">
                            <template v-if="item.productType == 5">￥{{(item.marketprice*item.selectNum).toFixed(2)}}+{{item.dedcredit*item.selectNum}}积分</template>
                            <template v-else>￥{{(item.marketprice*item.selectNum).toFixed(2)}}</template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="coupon-container mt-20 mb-10">
                <p>
                    <span class="font-bold coupon-label">平台优惠券</span>
                    <template v-if="couponsList.length == 0">
                        <span class="color-666">暂无可用</span>
                    </template>
                    <template v-else>
                        <span v-if="discountPrice > 0" class="color-666">系统已为您选中最优惠的优惠券，<i class="color-orange">抵用{{Number(discountPrice).toFixed(2)}}元</i></span>
                        <span v-else  class="color-666">您未选中任何优惠券</span>
                        <span class="all-coupon" @click="openCouponListFun">查看所有可用优惠券
                            <i v-if="!isOpen" class="el-icon-arrow-down font-bold"></i>
                            <i v-else class="el-icon-arrow-up font-bold"></i>
                        </span>
                    </template>
                </p>
                <ul v-if="isOpen" class="coupon-list flex flex-wrap">
                    <li class="coupon-item" :class="couponId === item.id ? 'active' : ''" 
                        v-for="item in couponsList" :key="item.id" @click="couponChange (item.id)">
                        <p class="title">
                            <span v-if="item.type == 2">{{item.amount.toFixed(1)}}折</span>
                            <span v-else>￥{{item.amount.toFixed(2)}}</span>
                        </p>
                        <div class="coupon-item-b">
                            <p class="font-12 mt-10">{{item.ticketName}}</p>
                            <p class="font-12 mt-10">
                                <span v-if="item.type == 1 && item.limit">{{item.limit}}张，</span>
                                <span>{{item.condition ? "满" + item.condition + "元可用" : "无使用门槛"}}</span>
                            </p>
                            <p class="font-12 mt-10">有效期截至：{{item.endTime | dateFormat('YYYY-MM-DD')}}</p>
                        </div>
                        <i v-if="couponId === item.id" class="active-icon el-icon-check"></i>
                    </li>
                </ul>
            </div>
            <div class="order-info flex">
                <div class="flex-1">
                    <div v-if="minOrderPrice>0" class="tips-wrapper flex align-c">
                        <i class="el-icon-warning"></i>
                        <span>最低下单金额{{minOrderPrice}}元<template v-if="difference>0">，还差{{difference}}元</template></span>
                    </div>
                    <el-form ref="remark" label-width="100px" style="width: 450px">
                        <el-form-item label="订单备注：">
                            <el-input type="textarea" v-model="remark" placeholder="请填写备注"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="order-info-r">
                    <p class="flex justify-s-b align-c">
                        <span class="font-bold label">非秒杀商品金额：</span>
                        <span>￥{{Number(notMiaoshaoPrice).toFixed(2)}}</span>
                    </p>
                    <p class="flex justify-s-b align-c mt-15">
                        <span class="label">配送方式：</span>
                        <span v-if="fashion === 0" class="color-999">到店自提</span>
                        <span v-else class="color-999">快递直邮</span>
                        <span v-if="allFirstPrice > 0">￥{{allFirstPrice.toFixed(2)}}</span>
                        <span v-else>免邮费</span>
                    </p>
                    <p class="flex justify-s-b align-c mt-15">
                        <span class="label">小计：</span>
                        <span v-if="scorchCount > 0" class="color-orange">￥{{Number(totalPrice).toFixed(2)}}+{{scorchCount}}积分</span>
                        <span v-else class="font-18">￥{{Number(totalPrice).toFixed(2)}}</span>
                    </p>
                    <p class="flex justify-s-b align-c mt-15 font-16 font-bold">
                        <span class="label">实付款：</span>
                        <span v-if="scorchCount > 0" class="color-orange font-20">￥{{Number(payPrice).toFixed(2)}}+{{scorchCount}}积分</span>
                        <span v-else class="color-orange font-20">￥{{Number(payPrice).toFixed(2)}}</span>
                    </p>
                </div>
            </div>
            <div  class="flex justify-e mt-20">
                <el-button class="submit-btn" v-if="scorchCount > 0 && points < scorchCount" disabled>积分不足</el-button>
                <el-button class="submit-btn" v-else :loading="loading" :disabled="isDisabled" @click="submitFn">提交订单</el-button>
            </div>
        </div>

        <el-dialog title="新增收货地址" width="600px" :visible.sync="showAddress" :close-on-click-modal="false" 
            :close-on-press-escape="false" @close="closeAddressFn">
            <div class="add-address-wrapper">
                <el-form :model="addAddress" ref="userInfo" label-width="100px">
                    <el-form-item label="收货人：">
                        <el-input v-model="addAddress.memName" autocomplete="off" placeholder="请输入收货人姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：">
                        <el-input v-model="addAddress.telNumber" type="tel" autocomplete="off" placeholder="请输入收货人手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="省市区：">
                        <el-cascader size="large" :options="options" v-model="selectedOptions" @change="addressChange"></el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址：">
                        <el-input type="textarea" v-model="addAddress.detailInfo" placeholder="请输入详细地址"></el-input>
                    </el-form-item>
                </el-form>
                <div class="flex justify-c">
                    <div v-if="addAddress.id" class="del-btn" @click="delAddressFn(0)">删除</div>
                    <div v-if="addAddress.id" class="save-btn" @click="delAddressFn(1)">保存</div>
                    <div v-else class="save-btn" @click="saveAddressFn">保存</div>
                </div>
            </div>
        </el-dialog>
        <wxPay :isWxPay="isWxPay" ref="wxpay" @visibleChange="isWxPay=$event" />
    </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import titleText from '@/components/titleText'
import wxPay from '@/components/wxPay'
export default {
    name: 'ConfirmOrder',
    components: { 
        titleText, 
        wxPay 
    },

    data() {
        return {
            active: 0,
            addressList: [],
            userInfo: {},
            addAddress: {},           //新增收货地址
            receiveInfo: {},          //收件人信息
            addressInfo: {},          //快递收货地址
            points: 0,                //积分
            fashion: '',              //0到店自提，1快递直发 
            balance: 0,               //余额
            minOrderPrice: 0,         //最低下单金额
            goodList: [],
            couponsList: [],          //可用优惠券
            couponId: [],             //优惠卷id
            notMiaoshaoGoods: [],     //非秒杀商品
            totalPrice: 0,            //商品金额
            notMiaoshaoPrice: 0,      //非秒杀商品金额
            scorchCount: 0,           //积分总数
            allFirstPrice: 0,         //总运费
            allWeight: 0,             //总重量
            dispatchId: 0,            //运费数据模板id
            discountPrice: 0,         //优惠卷抵扣金额
            payPrice: 0,              //优惠后金额
            difference: 0,            //最低下单金额-优惠后金额
            payType: 'wx',            //支付方式
            isDisabled: false,        //禁用提交按钮
            disabledYuPay: false,     //禁用余额支付
            remark: '',               //订单备注
            isOpen: false,            //展开优惠券列表
            orderId: null,            //订单ID
            payId: null,              //支付ID
            loading: false,
            isWxPay: false,
            showAddress: false,
            options: regionData,
            selectedOptions: []
        };
    },

    created() {
        let userInfo = this.$store.state.userInfo
        this.userInfo = userInfo
        this.receiveInfo = {
            name: userInfo.realname || userInfo.nickname,
            mobile: userInfo.mobile
        }
        if(userInfo.deliveryMethod == 1){
            this.fashion = 0
        } else if(userInfo.deliveryMethod == 2){
            this.fashion = 1
        } else {
            this.fashion = 0
        }

        this.getAddress()
        this.getMemberIntegral()
        this.getPrecreate()
    },

    methods: {
        //切换收货地址
        addressChange(arr) {
            this.addAddress.provinceName = CodeToText[arr[0]]
            this.addAddress.cityName = CodeToText[arr[1]]
            this.addAddress.countyName = CodeToText[arr[2]]
        },

        //关闭面板
        closeAddressFn() {
            this.addAddress = {}
            this.selectedOptions = []
        },

        //修改面板
        editAddressShowFn(index) {
            this.addAddress = this.addressList[index]
            let arg1 = TextToCode[this.addAddress.provinceName].code
            let arg2 = TextToCode[this.addAddress.provinceName][this.addAddress.cityName].code
            let arg3 = TextToCode[this.addAddress.provinceName][this.addAddress.cityName][this.addAddress.countyName].code
            this.selectedOptions = [arg1,arg2,arg3]
            this.showAddress = true
        },

        //删除、修改收货地址
        delAddressFn(flag){
            if(!flag){
                this.$confirm('确定删除该收货地址吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$https.post(this.$api.updAddress,{
                        mid: this.$store.state.userInfo.id,
                        id: this.addAddress.id,
                        isDefault: this.addAddress.isDefault,
                        usableFlag: 0,
                    })
                    .then((res) =>{
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        })
                        this.getAddress()
                        this.showAddress = false
                    })   
                }).catch(() => {})
            } else {
                if (!this.addAddress.memName){
                    this.$msgbox.alert('请填写收货人姓名', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return
                } else if (!this.addAddress.telNumber){
                    this.$msgbox.alert('请填写收货人手机号', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return
                } else if (!(/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(this.addAddress.telNumber))) {
                    this.$msgbox.alert('请填写正确的收货人手机号', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return
                } else if (!this.addAddress.provinceName || !this.addAddress.cityName || !this.addAddress.countyName) {
                    this.$msgbox.alert('请选择省市区', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return
                } else if (!this.addAddress.detailInfo) {
                    this.$msgbox.alert('请填写详细地址', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return
                }

                this.$https.post(this.$api.updAddress,{
                    mid: this.$store.state.userInfo.id,
                    id: this.addAddress.id,
                    memName: this.addAddress.memName,
                    telNumber: this.addAddress.telNumber,
                    provinceName: this.addAddress.provinceName,
                    cityName: this.addAddress.cityName,
                    countyName: this.addAddress.countyName,
                    detailInfo: this.addAddress.detailInfo,
                    isDefault: this.addAddress.isDefault,
                    nationalCode: 0,
                    usableFlag: 1
                })
                .then((res) =>{
                    this.$message({
                        message: '地址修改成功',
                        type: 'success'
                    })
                    this.getAddress()
                    this.showAddress = false
                })  

            }
        },

        //新增收货地址
        saveAddressFn() {
            if (!this.addAddress.memName){
                this.$msgbox.alert('请填写收货人姓名', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            } else if (!this.addAddress.telNumber){
                this.$msgbox.alert('请填写收货人手机号', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            } else if (!(/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(this.addAddress.telNumber))) {
                this.$msgbox.alert('请填写正确的收货人手机号', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            } else if (!this.addAddress.provinceName || !this.addAddress.cityName || !this.addAddress.countyName) {
                this.$msgbox.alert('请选择省市区', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            } else if (!this.addAddress.detailInfo) {
                this.$msgbox.alert('请填写详细地址', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            }

            this.$https.get(this.$api.saveAddress,{
                mid: this.$store.state.userInfo.id,
                memName: this.addAddress.memName,
                telNumber: this.addAddress.telNumber,
                provinceName: this.addAddress.provinceName,
                cityName: this.addAddress.cityName,
                countyName: this.addAddress.countyName,
                detailInfo: this.addAddress.detailInfo,
                nationalCode: 0,
                isDefault: 1,
                usableFlag: 1
            })
            .then((res) =>{
                this.$message({
                    message: '地址添加成功',
                    type: 'success'
                })
                this.getAddress()
                this.showAddress = false
            })
        },

        //获取收货地址
        getAddress() {
            this.$https.get(this.$api.addressList,{
                mid: this.$store.state.userInfo.id,
                offset: 0,
                limit: 100
            })
            .then((res) =>{
                this.addressList = res.data.rows
                if(res.data.rows.length > 0){
                    if(this.active < res.data.rows.length){
                        this.addressInfo = res.data.rows[this.active]
                    } else {
                        this.active = 0
                        this.addressInfo = res.data.rows[0]
                    }
                } else {
                    this.addressInfo = {}
                }
            })
        },

        //切换收货地址
        changeAddressFn(index) {
            if(this.active === index) return
            this.active = index
            this.addressInfo = this.addressList[index]
        },

        //获取积分
        getMemberIntegral() {
            this.$https.get(this.$api.getMemberIntegral,{
                mid: this.$store.state.userInfo.id
            })
            .then((res) =>{
                this.points = res.points
            })
        },

        //获取预提交订单
        getPrecreate() {
            this.$https.post(this.$api.precreate,{
                memberId: this.$store.state.userInfo.id,
                products: this.$store.state.cartItems,
                isSpellgroup: '',
                orderId: '',
            },{
                dataType: "json"
            })
            .then((res) =>{
                this.balance = res.balance || 0   //可用余额
                this.minOrderPrice = res.minOrderPrice || 0 //最低下单金额
                this.couponsList = res.coupons || []   //可用优惠券
                this.couponId = this.couponsList.length > 0 ? this.couponsList[0].id : ''

                if (res.overtime) {
                    this.isDisabled = true
                    this.$msgbox.alert(res.overTimeTitle, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                }
                if (res.limit) {
                    this.isDisabled = true
                    this.$msgbox.alert(res.limitTitle, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                }
                if(res.type){
                    this.isDisabled = true
                    this.$msgbox.alert('无购买权限', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                }

                this.onHandle(res.data)
            })
        },

        //逻辑处理
        onHandle(data) {
            let syncOrder = this.$store.state.cartItems

            let tempGoods = []
            data.forEach(goods => {
                goods.productId = goods.id
                for (let i = 0; i < syncOrder.length; i++) {
                    let cartItem = syncOrder[i]
                    if (cartItem.productId === goods.id && !cartItem.findGoods) {
                        goods.selectNum = cartItem.total || 1
                        goods.cartTotal = cartItem.total || 0
                        goods.cartId = cartItem.cartId

                        if (cartItem.skuId) {
                            goods.skuId = cartItem.skuId
                            goods.optionname = cartItem.skuTitle
                            goods.marketprice = cartItem.marketprice
                            goods.vipprice = cartItem.vipprice
                        }
                        cartItem.findGoods = true //用于同一商品多规格时的判断
                        break
                    }
                }
                tempGoods.push(goods)
            })

            //处理数据
            this.onDifferentiate(tempGoods)
            //商品金额
            this.totalPriceFun()
            //非秒杀商品金额
            this.notMiaoshaoPriceFun()
            //优惠卷抵扣金额
            this.discountPriceFun()
        },

        //区分数据
        onDifferentiate (data) {
            this.notMiaoshaoGoods = []
            let miaoshaGoods = []
            data.forEach((goods, i) => {
                if(goods.productType == 3){
                    miaoshaGoods.push(goods);
                } else {
                    this.notMiaoshaoGoods.push(goods)
                }
            })
            this.goodList =  miaoshaGoods.concat(this.notMiaoshaoGoods)
            //计算邮费
            this.getDispatchList()
        },

        //计算商品金额
        totalPriceFun () {
            let tempPrice = 0
            let tempScorch = 0

            this.goodList.reduce((selectNum, item) => {
                tempPrice += (this.userInfo.isVip===1 && item.isvip===1 && item.vipprice!=='' && this.userInfo.vipofcentreId.indexOf(this.userInfo.centreId) != -1) ? item.vipprice * item.selectNum : item.marketprice * item.selectNum
                if(item.productType == 5){
                    tempScorch += item.dedcredit * item.selectNum
                }
            }, 0)

            this.totalPrice = parseFloat(tempPrice).toFixed(2)
            this.scorchCount = tempScorch
            //计算优惠后金额
            this.payPriceFun()
            //是否可余额支付
            this.balanceDisableFun()
        },

        //优惠后金额
        payPriceFun () {
            let count = parseFloat(this.totalPrice) + parseFloat(this.allFirstPrice) - parseFloat(this.discountPrice)
            count = parseFloat(parseFloat(count).toFixed(2))
            this.payPrice = Math.max(count, 0)
            this.difference = (this.minOrderPrice*100 - Math.max(count, 0)*100)/100
            this.isDisabled = Math.max(count, 0) >= this.minOrderPrice ? false : true
        },

        //校验余额
        balanceDisableFun () {
            if(this.balance < this.payPrice) {
                this.disabledYuPay = true
                this.payType = 'wx'
            } else {
                this.disabledYuPay = false
            }
        },

        //计算非秒杀商品金额
        notMiaoshaoPriceFun () {
            let tempPrice = 0
            this.notMiaoshaoGoods.reduce((selectNum, item) => {
                tempPrice += (this.userInfo.isVip===1 && item.isvip===1 && item.vipprice!=='' && this.userInfo.vipofcentreId.indexOf(
                    this.userInfo.centreId) != -1) ? item.vipprice * item.selectNum : item.marketprice * item.selectNum
                }, 0)
            this.notMiaoshaoPrice = parseFloat(tempPrice).toFixed(2)
        },

        //优惠劵抵扣金额
        discountPriceFun () {
            //不使用优惠卷
            if (this.couponId != '' && this.couponId != -1) {
                this.couponsList.filter(item => {
                    if (this.couponId == item.id) {
                        if (item.type == 2)
                            this.discountPrice = (item.amount / 100) * this.totalPrice
                        else
                            this.discountPrice = item.amount
                    }
                })
            } else if (this.couponsList.length > 0) {
                this.discountPrice = 0
            } else {
                this.discountPrice = 0
            }
            //优惠后金额
            this.payPriceFun()
            //校验余额
            this.balanceDisableFun()
        },

        //商品总价-优惠卷抵扣
        payPriceTemp () {
            return Math.max(this.totalPrice - this.discountPrice, 0)
        },

        //计算邮费
        getDispatchList () {
            //到店自提，不计算邮费
            if (!this.fashion) {
                this.allFirstPrice = 0
                this.allWeight = 0
                this.dispatchId = 0
                //优惠后金额
                this.payPriceFun()
                //校验余额
                this.balanceDisableFun()
                return
            }

            let dispatchListTemp = []  //所有数据
            let dispatchList = []      //calculatetype=3通用数据
            let dispatchId = ''        //数据模板id
            let allWeight = 0          //总重量
            let allFirstPrice = 0      //总运费

            let postage = this.goodList.map(item => {
                if (item.isbig == 0) {//不包邮
                    return {
                        isKuaidi: item.isKuaidi,//0团购，1直发
                        weight: item.weight,//重量
                    }
                } else if (item.isbig == 1) {//包邮
                    console.log('包邮')
                }
            })

            let isReturn = true

            if (postage) {
                //得到总重量
                postage.forEach(item => {
                    if (item)
                        allWeight += parseInt(item.weight)
                    else
                        isReturn = false
                })
            }

            this.$https.post(this.$api.getDispatchList)
            .then((res) =>{
                dispatchListTemp = res.data

                dispatchListTemp.forEach(dispatchTemp => {
                    if (dispatchTemp.calculatetype == 3) {//calculatetype=3通用
                        dispatchId = dispatchTemp.id    //数据id
                        allFirstPrice = dispatchTemp.firstprice //起始运费
                        return
                    }
                })

                dispatchListTemp.forEach(dispatchTemp => {
                    if (dispatchTemp.parentId == dispatchId) {
                        dispatchList.push(dispatchTemp)
                    }
                })

                dispatchList.forEach(dispatch => {
                    //calculatetype=2包邮
                    //总价格大于后端配置价格，取当前数据运费，结束循环
                    if (dispatch.calculatetype == 2 && this.payPriceTemp() >= dispatch.firstweight) {
                        dispatchId = dispatch.id
                        allFirstPrice = dispatch.firstprice
                        allWeight = 0
                        isReturn = false
                    }
                    //订单总重量在后端配置之间，取当前数据运费
                    if (isReturn && allWeight >= dispatch.firstweight && allWeight <= dispatch.secondweight) {
                        dispatchId = dispatch.id
                        allFirstPrice = dispatch.firstprice
                    }
                })
                this.allFirstPrice = allFirstPrice
                this.allWeight = allWeight
                this.dispatchId = dispatchId
                //优惠后金额
                this.payPriceFun()
                //校验余额
                this.balanceDisableFun()
            })
        },

        //改变商品数量
        ChangeNumFn(currentValue, oldValue, index){
            let temp = `this.goodList[${index}].selectNum`
            let goodId = this.goodList[index].id
            let miaoshaoGoodsLength = this.goodList.length - this.notMiaoshaoGoods.length

            if(index >= miaoshaoGoodsLength){
                this.notMiaoshaoGoods[index - miaoshaoGoodsLength].selectNum = currentValue
            }
            let syncOrder = this.$store.state.cartItems
            // 修改商品数量，用于查询优惠券
            syncOrder.forEach((item,i)=>{
                if(item.productId == goodId) item.total = currentValue
            })
            this.$store.commit('SET_CART_ITEMS', syncOrder)

            this.$https.post(this.$api.precreate,{
                memberId: this.$store.state.userInfo.id,
                products: syncOrder,
                isSpellgroup: '',
                orderId: '',
            },{
                dataType: "json"
            })
            .then((res) =>{
                this.couponsList = res.coupons || []//可用优惠券
                this.couponId = this.couponsList.length > 0 ? this.couponsList[0].id : ''
                setTimeout(()=>{
                    //优惠卷抵扣金额
                    this.discountPriceFun()
                    //计算邮费
                    this.getDispatchList()
                    //商品金额
                    this.totalPriceFun()
                    if(index >= miaoshaoGoodsLength)
                        this.notMiaoshaoPriceFun() //非秒杀商品金额
                },500)
            })
        },

        //切换支付方式
        payTypeChangeFn(type) {
            if (this.payType != type)
                this.payType = type
        },

        //跳转商品详情
        toGoodDetail(id) {
            let { href } = this.$router.resolve({
                                name: "goodDetail",
                                query: {
                                    id: id
                                }
                            });
            window.open(href, '_blank')
        },

        //展开优惠券
        openCouponListFun() {
            this.isOpen = !this.isOpen
        },

        //优惠卷选择，使用优惠券
        couponChange (couponId) {
            if(this.couponId == couponId) return
            this.couponId = couponId
            this.discountPriceFun()
        },

        checkShop() {
            let isSleep = 0
            if (this.userInfo.status === 9) {
                return -1
            } else {
                if (this.userInfo.sleepTime) {//是否打烊
                    let startTime = this.$dayjs(this.userInfo.sleepStartTime).valueOf()
                    let endTime = this.$dayjs(this.userInfo.sleepEndTime).valueOf()
                    let serverTime = Date.now()
                    isSleep = (serverTime >= startTime) && (serverTime <= endTime)
                }

                return (isSleep ? 0 : 1)
            }
        },

        //提交订单
        submitFn() {
            if (this.fashion === 1 && this.addressInfo == '') {
                this.$msgbox.alert('请填写收货地址', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            }

            if (this.fashion === 0) {
                if (!this.receiveInfo.name || !this.receiveInfo.mobile || 
                    !(/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(this.receiveInfo.mobile))) {
                    this.$msgbox.alert('请填写正确的收件人姓名和电话', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return
                }
            }

            let checkShop = this.checkShop()
            if (checkShop != 1 && this.fashion === 0) {
                this.$msgbox.alert('当前门店已经关闭或者打烊中，请在小程序选择其他门店进行下单', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                .then(()=>{
                    this.$router.push({ name: 'home' })
                })
                return
            }

            let products = []
            this.goodList.map((item) => {
                products.push({
                    mid: this.userInfo.id,
                    teamid: this.userInfo.upshopTid,
                    openid: this.userInfo.openid,
                    id: item.cartId ? (item.cartId.toString().indexOf(':') == -1 ? item.cartId + ':null' : item.cartId) : '',
                    productId: item.id,
                    total: item.selectNum,
                    cartTotal: item.cartTotal,
                    skuId: item.skuId,
                    centreId: item.centreId,
                    isKuaidi: this.fashion === 1 ? 1 : 0
                })
            })

            this.loading = true
            this.$https.post(this.$api.createOrder,{
                memberId: this.userInfo.id,
                products: products,
                isKuaidi: this.fashion === 1 ? 1 : 0,
                addressEntity: this.fashion === 1 ? this.addressInfo : '',
                sname: this.fashion === 1 ? this.addressInfo.memName : this.receiveInfo.name,
                mobile: this.fashion === 1 ? this.addressInfo.telNumber : this.receiveInfo.mobile,
                houseNo: '',
                couponId: this.couponId,//优惠卷id
                allweight: this.allWeight,//重量
                dispatchprice: this.allFirstPrice,//运费
                dispatchid: this.dispatchId,//数据模板id
                iscomment: this.remark,//订单备注
            },{
                dataType: "json"
            })
            .then((res) =>{
                if (res.success) {
                    this.$store.dispatch('GET_CART_TOTAL')
                    this.payId = res.data
                    this.orderId = res.orderId
                    this.onPayment()
                } else {
                    this.$msgbox.alert(res.msg, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    this.loading = false
                }
            }).catch(() => {
                this.loading = false
            })  
        },

        //支付
        onPayment () {
            //余额支付
            if (this.payType == 'yu' || this.payPrice == 0) {
                this.$confirm(`余额支付：¥${this.payPrice.toFixed(2)}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$https.post(this.$api.yupay,{
                        orderId: this.payId,
                        shipcardRecordId: '',//充值卡id
                        couponId: this.couponId,//优惠卷id
                        points: this.scorchCount || ''//积分抵扣
                    })
                    .then((res) =>{
                        this.loading = false
                        this.$router.push({
                            name: 'payResult',
                            query: {
                                orderId: this.orderId
                            }
                        })
                    })
                }).catch(() => {})
            }
            //微信支付
            else if (this.payType == 'wx') {
                this.$https.post(this.$api.apppay,{
                    payType: 1,
                    orderId: this.payId,
                    couponId: this.couponId,//优惠卷id
                    points: this.scorchCount || ''//积分抵扣
                })
                .then((res) =>{
                    let qrcode = res.data.package.replace('code_url=', '')
                    this.loading = false
                    this.isWxPay = true
                    this.$refs.wxpay.init(qrcode, this.payId, this.couponId, this.scorchCount)
                })
            }
        }
    }
}
</script>
<style lang='scss' scoped>
    .info-wrapper{
        color: #606266;
        .label{
            width: 100px;
            padding-right: 12px;
            text-align: right;
        }
        .user-wrapper{
            width: 360px;
        }
        .address-item{
            width:285px;
            height: 140px;
            padding: 15px 20px;
            margin: 0 20px 20px 0;
            border: 1px solid #dedede;
            cursor: pointer;
            position: relative;
            &:nth-child(4n){
                margin-right: 0;
            }
            &.active{
                border-color: transparent;
            }
            .address-item-edit{
                position: absolute;
                top: 15px;
                right: 20px;
                display: none;
                &:hover{
                    color: var(--blue-color);
                }
                i{
                    margin-right: 3px;
                }
            }
            &:hover{
                .address-item-edit{
                    display: flex;
                }
            }
            .line{
                background: -webkit-repeating-linear-gradient( 135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
                background: repeating-linear-gradient( -45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
            }
            .line1{
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 2px;
                background-size: 80px;
            }
            .line2{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 2px;
                background-size: 80px;
            }
            .line3{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 2px;
                background-size: 45px 45px;
            }
            .line4{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 2px;
                background-size: 45px 45px;
            }
            .plus-icon{
                color: #999;
                font-size: 36px;
                margin-bottom: 15px;
            }
        }
        .add-address{
            cursor: pointer;
            &:hover{
                color: var(--blue-color);
            }
        }
    }
    .payment-type-wrapper{
        .payment-type{
            width: 220px;
            height: 68px;
            margin-right: 30px;
            border: 1px solid #dedede;
            border-radius: 5px;
            cursor: pointer;
            &.active{
                border-color: #ff0036;
                background-color: rgba(255, 0, 54, .05);
            }
            &.disabled{
                cursor: not-allowed;
                background-color: #dedede;
            }
            i{
                width: 40px;
                height: 40px;
                margin-right: 5px;
                background: url(../../assets/images/sprite.png) no-repeat;
            }
            &.wx{
                i{
                    background-position: -141px -112px; 
                }
            }
            &.ye{
                i{ 
                    background-position: -194px -112px;  
                }
            }
        }
    }
    .good-list-container{
        margin-top: 35px;
        .good-list-wrapper{
            padding: 0 19px;
            border: 1px solid #DEDEDE;
            background-color: rgba(12, 56, 144, 0.07);
        }
    }
    .th{
        height: 36px;
        padding: 0 20px;
        text-align: center;
        border: 1px solid #DEDEDE;
        background-color: #f1f1f1;
        .th-info{
            flex: 0 0 580px;
        }
        .th-price{
            flex: 0 0 200px;
        }
        .th-amount{
            flex: 0 0 180px;
        }
        .th-sum{
            flex: 0 0 200px;
        }
    }
    .tr{ 
        padding: 15px 10px;
        border-bottom: 1px solid #DEDEDE;
        &:last-child{
            border: none;
        }
        .tr-info{
            flex: 0 0 580px;
            .good-info{
                max-width: 400px;
                .iden{
                    display: inline-block;
                    padding: 2px 8px;
                    color: #FF0000;
                    border-radius: 3px;
                    text-align: center;
                    border: 1px solid #FF0000;
                }
            }
        }
        .tr-price{
            flex: 0 0 200px;
            text-align: center;
        }
        .tr-amount{
            flex: 0 0 180px;
            text-align: center;
        }
        .tr-sum{
            flex: 0 0 180px;
            text-align: right;
        }
        .good-image{
            cursor: pointer;
            border: 1px solid #DEDEDE;
        }
        .title{
            cursor: pointer;
            &:hover{
                color: var(--blue-color);
                text-decoration: underline;
            }
        }
    }
    .coupon-container{
        padding: 19px;
        border: 1px solid #DEDEDE;
        .coupon-label{
            margin-right: 50px;
        }
        .all-coupon{
            margin-left: 30px;
            color: var(--blue-color);
            cursor: pointer;
        }
        .coupon-list{
            margin: 20px 0;
            padding: 20px 10px 0;
            background-color: #f1f1f1;
            .coupon-item{
                width: 270px;
                padding-bottom: 20px;
                margin-right: 20px;
                margin-bottom: 20px;
                background-color: #fff;
                position: relative;
                cursor: pointer;
                border: 2px solid transparent;
                &.active{
                    border-color: var(--red-color);
                }
                &:nth-child(4n){
                    margin-right: 0;
                }
                .title{
                    padding: 0 10px;
                    color: #fff;
                    font-size: 18px;
                    font-weight: bolder;
                    line-height: 40px;
                    background: linear-gradient(90deg, #0c3890, #7e84f9);
                }
                .coupon-item-b{
                    padding: 10px 10px 0 10px;
                }
                &.active::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 0px;
                    height: 0px;
                    border-color: var(--red-color) transparent;
                    border-width: 0 0 30px 30px;
                    border-style: solid;
                }
                .active-icon{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: 99;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }
    .order-info{
        margin-top: 30px;
        padding: 19px 20px 20px 0;
        border-top: 1px solid #dedede;
        background-color: #f1f1f1;
        .tips-wrapper{
            color: #FF0000;
            padding-left: 18px;
            margin-bottom: 20px;
            i{
                color: #969799;
                font-size: 16px;
                margin-right: 5px;
            }
        }
        .order-info-r{
            width: 360px;
            .label{
                width: 120px;
                text-align: right;
            }
        }
    }
    .submit-btn{
        height: 48px;
        padding: 12px 46px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border: none;
        background-color: var(--orange-color);
        &.is-disabled{
            background-color: #f3ad85;
            &:hover{
                color: #fff;
                background-color: #f3ad85;
            }
        }
    }
    .add-address-wrapper{
        width: 400px;
        margin: 0 auto;
        padding: 0 20px 20px 0;
        .save-btn,
        .del-btn{
            width: 150px;
            height: 42px;
            margin-top: 30px;
            color: #fff;
            line-height: 42px;
            text-align: center;
            border-radius: 3px;
            cursor: pointer;
            background-color: var(--orange-color);
        }
        .del-btn{
            background-color: #999;
            margin-right: 20px;
        }
    }
</style>